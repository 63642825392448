import Button from "@mkt/ui/components/Ccbr/Button"
import Img from "@mkt/ui/components/common/Img"
import { storyblokEditable } from "@storyblok/react"

import useCardsFeatured from "./hook"
import * as S from "./styles"

const CardsFeatured = ({ type, blok }) => {
  const highlights = blok?.body
  const { handleProductClick } = useCardsFeatured({ highlights })

  return (
    <section
      {...storyblokEditable(blok)}
      css={S.SuggestedCards}
      data-scroll="productListViewed"
    >
      <div css={S.SuggestedCardsContainer}>
        <h2 css={S.SuggestedCardsTitle}>Cartões em destaque</h2>
        <div css={S.SuggestedCardsSelection}>
          {highlights?.map((card, index) => {
            const { title, annuity, minimum_income: income, image } = card.content

            return (
              <div
                css={S.SuggestedCardsBox}
                key={`card-featured-box-${index}`}
                data-scroll="productViewed"
                data-position={index}
              >
                <div css={S.SuggestedCardsImageWrapper}>
                  <Img
                    src={image?.filename}
                    alt={title}
                    width="236px"
                    height="146px"
                  />
                </div>
                <div css={S.BoxTitle}>
                  <h3>{title}</h3>
                </div>
                <div css={S.BoxDesc}>
                  <div>
                    <span>Renda Mínima:</span>
                    <span>{income}</span>
                  </div>

                  <div>
                    <span>Anuidade:</span>
                    <span>{annuity}</span>
                  </div>
                </div>
                <Button
                  type="large"
                  htmlType="button"
                  css={S.BoxButton}
                  onClick={() => handleProductClick(card, index)}
                >
                  {type === "home" ? "Peça já" : "Mais informações"}
                </Button>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default CardsFeatured
