import { useEffect } from "react"

import useAnalytics from "@mkt/ui/hooks/useAnalytics"

import { navigate } from "gatsby"

const useCardsFeatured = ({ highlights }) => {
	const { product, productsList, list } = useAnalytics()
	const currentListId =  "cartoes-em-destaque"
	const currentListName =  "Cartões em destaque"

	const handleProductClick = (card, position) => {
    const { content, slug } = card
    const redirectUrl = content?.redirect_url

    const isProductRedirect = redirectUrl && redirectUrl.startsWith("https://www.iq.com.br/produto")
    const productSlug = isProductRedirect ? redirectUrl : `/${slug}`
    
		product.setProduct({ list, position, ...card })
		
		navigate(productSlug)
  }


	useEffect(() => {
		list.setList({ id: currentListId, name: currentListName})
	}, [])
  
	useEffect(() => {
		if (!productsList.data) {
			productsList.setProductsList(highlights)
		}
	}, [ productsList.data ])

	return {
		handleProductClick
	}
}

export default useCardsFeatured